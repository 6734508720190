// src/components/CanonicalLink.jsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const CanonicalLink = () => {
  const location = useLocation();
  const baseUrl = window.location.origin;  // Get the current domain dynamically

  return (
    <Helmet>
      <link rel="canonical" href={`${baseUrl}${location.pathname}`} />
    </Helmet>
  );
};

export default CanonicalLink;

