/* FooterContacts.jsx */
import React from 'react';

function FooterContacts() {
    return (      
        <div className="lg:px-8 px-4">
            <div className="text-magenta col-title"><i className="fa-solid fa-phone text-magenta"></i> Call Us</div>
            <a href="tel:+13104268542" className='text-white'>+1(310)426-8542</a>
        </div>   
    );
}

export default FooterContacts;
