/* FooterBottom.jsx */
import React from 'react';

function FooterLinks() {
    return (     
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:px-8 px-0">
            <div className="lg:px-8 px-4 lg:text-left text-center">
                <p className="text-white">&copy; 2024 Made with <i className="fa-solid fa-heart"></i>. All Rights Reserved.</p>
            </div> 
            <div className="lg:px-0 px-4 lg:text-right text-center">
                <ul>
                    <li className='lg:pr-2 lg:inline'><a href="/acceptable-use-policy">Acceptable Use Policy</a></li>
                    <li className='lg:pr-2 lg:pl-2 lg:inline'><a href="/privacy-policy">Privacy Policy</a></li>
                    <li className='lg:pl-2 lg:inline'><a href="/terms-and-conditions">Terms and Conditions</a></li>
                </ul>
            </div> 
        </div>    
    );
}

export default FooterLinks;
