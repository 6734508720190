import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-[50vh] w-full flex items-center justify-center">
      <div className="text-center space-y-6 px-4">
        <div className="space-y-2">
          <h1 className="text-4xl font-bold tracking-tighter">404</h1>
          <h2 className="text-2xl font-semibold text-gray-700">Page not found</h2>
          <p className="text-gray-500 max-w-lg mx-auto">
            Sorry, we couldn't find the page you're looking for. Please check the URL or return home.
          </p>
        </div>
        <div className="flex justify-center space-x-4">
          <button 
            onClick={() => navigate(-1)}
            className="px-4 py-2 bg-gray-50 order rounded-lg hover:bg-gray-50"
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
