/* FooterEmail.jsx */
import React from 'react';

function FooterEmail() {
    return (         
        <div className="lg:px-8 px-4">
            <div className="text-magenta col-title"><i className="fa-solid fa-envelope text-magenta"></i> Email Us</div>
            <a href="mailto:innovators@gocomply.ai" className='text-white'>innovators@gocomply.ai</a>
        </div>   
    );
}

export default FooterEmail;
