import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import '@fortawesome/fontawesome-free/css/all.css';

import Header from './app/global/Header/Header';
import Footer from './app/global/Footer/Footer';
import NotFound from './app/global/NotFound/NotFound';
import Home from './app/home/Home';
import About from './app/about/About';
import AcceptPolicy from './app/acceptable-use-policy/AcceptPolicy';
import PrivacyPolicy from './app/privacy-policy/PrivacyPolicy';
import TermsConditions from './app/terms-and-conditions/TermsConditions';
import CanonicalLink from './components/CanonicalLink';

const App = () => {
  return (
    <HelmetProvider>
      <main>
        <Header />
        <Router>
          {/* Set the canonical URL dynamically based on the current path */}
          <CanonicalLink />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/acceptable-use-policy" element={<AcceptPolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            {/* 404 route - must be last */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
        <Footer />
      </main>
    </HelmetProvider>
  );
};

export default App;

