/* FooterLinks.jsx */
import React from 'react';

function FooterLinks() {
    return (      
        <div className="lg:px-8 px-4 text-center mb-4">
            <div className="text-magenta col-title">Follow Us</div>
            <p className="soc-med text-black">
                <a href="https://www.facebook.com/complyai" target="_blank" rel="noreferrer" className="p-2 my-2 mx-1 rounded-full inline-grid bg-magenta"><i className="fa-brands fa-facebook-f"></i></a> 
                <a href="https://www.linkedin.com/company/comply-ai/" target="_blank" rel="noreferrer" className="p-2 my-2 mx-1 rounded-full inline-grid bg-magenta"><i className="fa-brands fa-linkedin-in"></i></a></p>
        </div>    
    );
}

export default FooterLinks;
